import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Highlight from "react-highlight.js"

import "../../styles/blog.css"
import "../../styles/atom-one-dark-reasonable.css"

export default function RenderingPartialOutsideViewOrControllerRails6() {
  return (
    <Layout>
      <SEO title="Rendering a Partial Outside of a View or Controller in Rails 6 | Primespot Blog" />
      <main>
        <h1>Rendering a Partial Outside of a View or Controller in Rails 6</h1>
        <p className="text-gray-600 mt-2 mb-4" style={{ fontSize: "16px" }}>
          Primespot Engineering - June 04, 2020
        </p>
        <div className="mb-4">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-teal-100 text-teal-800">
            Engineering
          </span>
        </div>
        <p>
          There are times when working within the Rails MVC architecture when
          you will want to render a partial from non-traditional areas of your
          application. Recently, I ran into this requirement while working with
          a rails library called <a href="https://github.com/hopsoft/cable_ready">cable_ready</a>.
        </p>
        <p>
          This library allows us to update the DOM from the server real-time
          over ActionCable. This is pretty cool, because it can replace a
          front-end library such as React in many situations with much less
          code.
        </p>
        <p>
          You might see examples online where cable_ready is used to render a
          partial from within a controller. This works fine just the way you’d
          expect:
        </p>
        <Highlight language="rails">{'render "path/to/partial"'}</Highlight>
        <p>
          But what happens when you want to render this partial from outside of
          the normal scope?
        </p>
        <p>
          For example, I wanted to use cable_ready to render the partial from
          within a Stimulus Reflex file. The render method won’t work here. At
          least not the same way.
        </p>
        <p>Fortunately, the solution is very simple. Use this code:</p>
        <Highlight language="rails">
          {'ApplicationController.render "path/to/partial"'}
        </Highlight>
        <p>
          By simply scoping the render method to ApplicationController,
          everything should work fine. This will work within a Stimulus Reflex
          file and even from within a .js.erb file if you’re using that.
        </p>
      </main>
    </Layout>
  )
}
